<template>
	<v-layout class="user-listing h-100 flex-wrap">
		<v-flex md3 class="border-right">
			<div class="listing-header d-flex px-3">
				<v-menu offset-y right bottom content-class="elevation-3" rounded="0">
					<template v-slot:activator="{ on, attrs }">
						<p class="sub-heading m-0" v-on="on" v-bind="attrs">
							{{ status }}
							<v-icon>mdi-menu-down</v-icon>
						</p>
					</template>
					<v-list>
						<v-list-item
							v-on:click="
								status = 'active';
								get_users();
							"
						>
							<v-list-item-title class="d-flex align-items-center">
								Active <span class="circle green"></span>
								<v-spacer></v-spacer>
								<div class="cm-budge green white--text">{{ status_count.active }}</div>
							</v-list-item-title>
						</v-list-item>
						<v-list-item
							v-on:click="
								status = 'in-active';
								get_users();
							"
						>
							<v-list-item-title class="d-flex align-items-center">
								In-Active <span class="circle orange"></span>
								<v-spacer></v-spacer>
								<div class="cm-budge orange white--text">{{ status_count.inactive }}</div>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-spacer></v-spacer>
				<v-btn
					depressed
					tile
					color="blue darken-4"
					class="white--text"
					v-on:click="
						e_user = {};
						add_new_user = true;
					"
				>
					<v-icon>mdi-plus</v-icon> New User</v-btn
				>
			</div>
			<div class="user-listing" style="max-height: calc(100vh - 140px); overflow-y: scroll">
				<template v-if="users.length">
					<div
						v-for="(row, index) in users"
						:key="index"
						class="user-listing-card d-flex border-bottom cursor-pointer"
						:class="{ 'user-selected': user ? row.id == user.id : false }"
						v-on:click="select_user(row)"
					>
						<div style="width: 15%" class="avater mr-4">
							<img :src="row.profile" :alt="row.display_name" width="100%" height="100%" />
						</div>
						<div style="width: 85%" class="inforamtion">
							<div class="name fw-600">
								<v-layout>
									<v-flex>
										<v-icon class="icon-18 blue--text mr-1">mdi-account</v-icon> {{ row.display_name }}
									</v-flex>
									<v-flex class="text-right">
										<div>
											<v-chip
												:color="row.activated ? 'green' : 'red'"
												class="white--text mr-2"
												outlined
												small
												style="height: 16px; padding: 0px 8px"
											>
												{{ row.activated ? "Active" : "In-Active" }}
											</v-chip>
										</div>
									</v-flex>
								</v-layout>
							</div>
							<div class="email fw-500">
								<v-icon class="icon-18 blue--text mr-1">mdi-email-outline</v-icon> {{ row.email }}
							</div>
							<div class="email fw-500">
								<v-icon class="icon-18 blue--text mr-1">mdi-phone</v-icon> {{ row.phone_number }}
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="text-center">
						<p v-if="filter.status" class="font-level-3-bold my-5">Ooops... Nothing Found.</p>
						<p v-else class="font-level-3-bold my-5">Ooops... There are no user at the moment.</p>
					</div>
				</template>
			</div>
		</v-flex>
		<v-flex md9 v-if="user && user.id" class="user-detail-layout">
			<div class="d-flex pt-4">
				<v-spacer></v-spacer>
				<v-chip
					v-on:click="status_dialog = true"
					:color="user.activated ? 'red' : 'green'"
					class="white--text mr-2"
					label
					style="letter-spacing: 0.8px"
				>
					{{ user.activated ? "Mark as In-Active" : "Mark as Active" }}
				</v-chip>
			</div>
			<div class="user-listing-card d-flex border-bottom">
				<div class="avater">
					<img :src="user.profile" :alt="user.display_name" />
				</div>
				<v-flex class="inforamtion pl-4 w-100">
					<div class="name d-flex">
						<div class="d-flex">
							<h4 class="fw-600 mb-0">{{ user.display_name }}</h4>
							<v-chip
								v-if="user.activated"
								color="green"
								class="white--text ml-2"
								outlined
								style="letter-spacing: 0.8px"
								small
							>
								Active
							</v-chip>
							<v-chip
								v-else
								color="red"
								class="white--text ml-2"
								outlined
								style="letter-spacing: 0.8px"
								small
							>
								In-Active
							</v-chip>
						</div>
						<v-spacer></v-spacer>
						<v-btn depressed tile v-on:click="edit_user()" color="white"
							>Edit <v-icon link class="ml-2 icon-18">mdi-pencil</v-icon></v-btn
						>
					</div>
					<div class="d-flex">
						<div class="email mt-1 fw-500">
							<v-icon class="icon-18 blue--text">mdi-email-outline</v-icon>
							{{ user.email }}
						</div>
						<div class="email ml-5 mt-1 fw-500">
							<v-icon class="icon-18 blue--text">mdi-phone</v-icon>
							{{ user.phone_number }}
						</div>
					</div>
				</v-flex>
			</div>
			<div class="user-information px-4 pt-2">
				<table width="100%">
					<tr>
						<th colspan="2">User Information</th>
					</tr>
					<tr>
						<td width="20%" align="right" class="pr-3 py-1">First Name</td>
						<th width="80%" align="left" class="pl-3 py-1">{{ user.first_name }}</th>
					</tr>
					<tr>
						<td width="20%" align="right" class="pr-3 py-1">Last Name</td>
						<th width="80%" align="left" class="pl-3 py-1">{{ user.last_name }}</th>
					</tr>
					<tr>
						<td width="20%" align="right" class="pr-3 py-1">Display Name</td>
						<th width="80%" align="left" class="pl-3 py-1">{{ user.display_name }}</th>
					</tr>
					<tr>
						<td width="20%" align="right" class="pr-3 py-1">Password</td>
						<th width="80%" align="left" class="pl-3 py-1">
							********
							<v-icon small v-on:click="update_password_dailog = true" class="py-0 my-0 ml-2"
								>mdi-pencil</v-icon
							>
						</th>
					</tr>
					<tr>
						<td width="20%" align="right" class="pr-3 py-1">Email</td>
						<th width="80%" align="left" class="pl-3 py-1">{{ user.email }}</th>
					</tr>
					<tr>
						<td width="20%" align="right" class="pr-3 py-1">Phone</td>
						<th width="80%" align="left" class="pl-3 py-1">{{ user.phone_number }}</th>
					</tr>
				</table>
			</div>
		</v-flex>
		<add-user
			v-if="add_new_user"
			v-on:success="get_users()"
			v-on:select="select_row($event)"
			:drawer="add_new_user"
			v-model="e_user"
			v-on:close="
				e_user = {};
				add_new_user = false;
			"
		></add-user>
		<Dialog :dialog="update_password_dailog" dense>
			<template v-slot:title>
				<p class="mb-0">Update Password</p>
			</template>
			<template v-slot:body>
				<v-form
					ref="passwordForm"
					v-model="form_valid"
					lazy-validation
					v-on:submit.stop.prevent="update_password()"
				>
					<div class="px-8 pb-8 pt-4">
						<table width="100%">
							<tr>
								<td width="30%" valign="middle">
									<label for="old-password" class="my-0 py-0">Old Password</label>
								</td>
								<td width="70%">
									<TextInput
										dense
										:append-icon="show_old ? 'mdi-eye' : 'mdi-eye-off'"
										v-on:click:append="show_old = !show_old"
										:type="show_old ? 'text' : 'password'"
										id="old-password"
										hide-details
										v-model="old_password"
										placeholder="Enter old password"
										:disabled="p_loading"
										:loading="p_loading"
										class="mt-0"
										:rules="[vrules.required(old_password, 'Old Password')]"
									></TextInput>
								</td>
							</tr>
							<tr>
								<td width="30%" valign="middle">
									<label for="new-password" class="my-0 py-0">New Password</label>
								</td>
								<td width="70%">
									<TextInput
										dense
										:append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
										v-on:click:append="show_new = !show_new"
										:type="show_new ? 'text' : 'password'"
										id="new-password"
										hide-details
										v-model="new_password"
										placeholder="Enter new password"
										:disabled="p_loading"
										:loading="p_loading"
										class="mt-0"
										:rules="[vrules.required(new_password, 'New Password')]"
									></TextInput>
									<template v-if="new_password">
										<v-layout class="mt-1">
											<v-flex>
												<v-progress-linear
													color="red darken-2"
													rounded
													height="5px"
													value="100"
												></v-progress-linear>
											</v-flex>
											<v-flex class="mx-2">
												<v-progress-linear
													:color="validate_password(new_password).id >= 2 ? 'yellow darken-2' : 'grey lighten-2'"
													rounded
													height="5px"
													value="100"
												></v-progress-linear>
											</v-flex>
											<v-flex>
												<v-progress-linear
													:color="validate_password(new_password).id == 3 ? 'green darken-2' : 'grey lighten-2'"
													rounded
													height="5px"
													value="100"
												></v-progress-linear>
											</v-flex>
										</v-layout>
										<p
											class="mt-1"
											:class="{
												'red--text text--darken-2': validate_password(new_password).id < 2,
												'yellow--text text--darken-2': validate_password(new_password).id == 2,
												'green--text text--darken-2': validate_password(new_password).id == 3,
											}"
										>
											Password Strength: {{ validate_password(new_password).value }}
										</p>
									</template>
								</td>
							</tr>
							<tr>
								<td width="30%" valign="middle">
									<label for="confirm-password" class="my-0 py-0">Confirm Password</label>
								</td>
								<td width="70%">
									<TextInput
										dense
										:append-icon="show_confirm ? 'mdi-eye' : 'mdi-eye-off'"
										v-on:click:append="show_confirm = !show_confirm"
										:type="show_confirm ? 'text' : 'password'"
										id="confirm-password"
										hide-details
										v-model="confirm_password"
										placeholder="Enter confirm password"
										:disabled="p_loading"
										:loading="p_loading"
										class="mt-0"
										:rules="[vrules.required(confirm_password, 'Confirm Password')]"
									></TextInput>
								</td>
							</tr>
						</table>
					</div>
				</v-form>
			</template>
			<template v-slot:action>
				<div>
					<v-btn
						:disabled="!form_valid || p_loading || validate_password(new_password).id < 2"
						:loading="p_loading"
						color="blue darken-4 white--text"
						class="mr-2"
						v-on:click="update_password()"
						tile
						depressed
						>Update</v-btn
					>
					<v-btn :disabled="p_loading" v-on:click="update_password_dailog = false" tile depressed
						>Cancel</v-btn
					>
				</div>
			</template>
		</Dialog>
		<Dialog :dialog="status_dialog" dense>
			<template v-slot:title>
				<p class="mb-0">
					Are you sure, you want to mark as
					<span :class="{ 'red--text': user.activated == 1, 'green--text': user.activated == 0 }">{{
						user.activated ? "deactivated" : "activated"
					}}</span
					>?
				</p>
			</template>
			<template v-slot:action>
				<div>
					<v-btn
						:disabled="s_loading"
						:loading="s_loading"
						color="blue darken-4 white--text"
						class="mr-2"
						v-on:click="update_status()"
						tile
						depressed
						>Yes! Update</v-btn
					>
					<v-btn :disabled="s_loading" v-on:click="status_dialog = false" tile depressed
						>No! Cancel</v-btn
					>
				</div>
			</template>
		</Dialog>
	</v-layout>
</template>
<script>
import AddUser from "@/view/pages/components/AddUser";
import TextInput from "@/view/components/TextInput";
import Dialog from "@/view/components/Dialog";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import ObjectPath from "object-path";
import { cloneDeep, find } from "lodash";
import { ERROR } from "@/core/services/store/common.module";
import { passwordStrength } from "check-password-strength";

export default {
	name: "users",
	components: {
		AddUser,
		Dialog,
		TextInput,
	},
	data() {
		return {
			selectedItem: 0,
			s_loading: false,
			status_dialog: false,
			new_password: null,
			show_new: false,
			confirm_password: null,
			show_confirm: false,
			old_password: null,
			show_old: false,
			form_valid: true,
			add_new_user: false,
			p_loading: false,
			loading: false,
			users: [],
			user: {},
			e_user: {},
			update_password_dailog: false,
			filter: {
				"page-no": 1,
				"page-limit": 50,
				search: null,
			},
			status: "active",
			status_count: {
				active: 0,
				inactive: 0,
			},
			t_uuid: null,
			timeout: null,
			timeoutLimit: 200,
		};
	},
	watch: {
		$route() {
			this.search();
		},
	},
	methods: {
		search() {
			const _this = this;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				_this.get_users();
			}, _this.timeoutLimit);
		},
		validate_password(password) {
			return passwordStrength(password);
		},
		update_status() {
			this.s_loading = true;
			this.$store
				.dispatch(PATCH, { url: `users/${this.user.uuid}/status` })
				.then(() => {
					this.get_users();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.s_loading = false;
				});
		},
		update_password() {
			this.$refs.passwordForm.validate();

			const formErrors = this.validateForm(this.$refs.passwordForm);

			if (formErrors.length) {
				this.$store.dispatch(ERROR, formErrors);
				return false;
			}

			if (!this.$refs.passwordForm.validate()) {
				return false;
			}

			if (this.new_password != this.confirm_password) {
				this.$store.dispatch(ERROR, [
					{
						model: true,
						message: "Confirm password doesn't matched with new password.",
						timeout: 2000,
					},
				]);
				return false;
			}

			const request = {
				old_password: this.old_password,
				password: this.new_password,
				password_confirmation: this.confirm_password,
			};

			this.p_loading = true;

			this.$store
				.dispatch(PATCH, { url: `users/${this.user.uuid}/password`, data: request })
				.then(() => {
					this.update_password_dailog = false;
					this.old_password = null;
					this.new_password = null;
					this.confirm_password = null;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.p_loading = false;
				});
		},
		edit_user() {
			this.add_new_user = true;
			this.e_user = cloneDeep(this.user);
		},
		select_user(row) {
			this.user = row;
		},
		select_row(uuid) {
			this.t_uuid = uuid;
		},
		get_users() {
			this.loading = true;
			this.update_password_dailog = false;
			this.status_dialog = false;
			this.add_new_user = false;
			this.e_user = {};
			this.users = [];
			this.user = {};

			this.filter["status"] = this.status;
			this.filter["page-no"] = ObjectPath.get(this.$route, "query.page-no", 1);
			this.filter["page-limit"] = ObjectPath.get(this.$route, "query.page-limit", 50);
			this.filter["search"] = ObjectPath.get(this.$route, "query.search-item-name", null);

			this.$store
				.dispatch(QUERY, { url: `users`, data: this.filter })
				.then((response) => {
					this.status_count.inactive = ObjectPath.get(response, "status.deactive", 0);
					this.status_count.active = ObjectPath.get(response, "status.active", 0);
					this.users = ObjectPath.get(response, "rows", []);
					this.user = ObjectPath.get(this.users, "0", {});
					this.filter["page-no"] = ObjectPath.get(response, "page-no");
					this.filter["page-limit"] = ObjectPath.get(response, "page-limit");
					if (this.t_uuid) {
						const t_row = find(this.users, { uuid: this.t_uuid });
						if (t_row) {
							this.select_user(t_row);
						}
						this.$nextTick(() => {
							this.t_uuid = null;
						});
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.get_users();
	},
};
</script>

<style scoped></style>
