<template>
	<div>
		<v-navigation-drawer v-model="drawer" absolute width="600" right temporary stateless>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header white--text orange darken-2">
					<div class="font-level-3-bold my-auto">
						<template v-if="userUUID">Update User</template>
						<template v-else>Add New User</template>
					</div>
					<v-spacer></v-spacer>
					<v-btn
						:disabled="!form_valid || loading || (!userUUID && validate_password(password).id < 2)"
						:loading="loading"
						tile
						depressed
						color="blue darken-4"
						class="white--text"
						v-on:click="update_or_create()"
					>
						<template v-if="userUUID">Update</template>
						<template v-else>Save</template>
					</v-btn>
					<v-btn tile :disabled="loading" depressed class="ml-2" v-on:click="$emit('close', true)"
						>Cancel</v-btn
					>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<v-form
						ref="userForm"
						v-model="form_valid"
						lazy-validation
						v-on:submit.stop.prevent="update_or_create()"
					>
						<div class="d-form-grp">
							<label class="mt-2" for="first-name">First Name</label>
							<TextInput
								dense
								id="first-name"
								hide-details
								v-model="first_name"
								placeholder="Enter first name"
								:disabled="loading"
								:loading="loading"
								class="mt-0"
								:rules="[vrules.required(first_name, 'First Name')]"
							></TextInput>
						</div>
						<div class="d-form-grp">
							<label class="mt-2" for="last-name">Last Name</label>
							<TextInput
								dense
								id="last-name"
								hide-details
								v-model="last_name"
								placeholder="Enter last name"
								:disabled="loading"
								:loading="loading"
								class="mt-0"
							></TextInput>
						</div>
						<div class="d-form-grp">
							<label class="mt-2" for="display-name">Display Name</label>
							<TextInput
								dense
								id="display-name"
								hide-details
								v-model="display_name"
								placeholder="Enter display name"
								:disabled="loading"
								:loading="loading"
								class="mt-0"
								:rules="[vrules.required(display_name, 'Display Name')]"
							></TextInput>
						</div>
						<div class="d-form-grp">
							<label class="mt-2" for="phone-no">Phone No.</label>
							<Phone
								dense
								id="phone-no"
								hide-details
								v-model="phone_number"
								placeholder="Enter phone no."
								:disabled="loading"
								:loading="loading"
								class="mt-0"
							></Phone>
						</div>
						<div class="d-form-grp">
							<label class="mt-2" for="email">Email</label>
							<EmailInput
								dense
								id="email"
								hide-details
								v-model="email"
								placeholder="Enter email"
								:disabled="loading"
								:loading="loading"
								:rules="[vrules.required(email, 'Display Name')]"
								class="mt-0"
							></EmailInput>
						</div>
						<div v-if="!userUUID" class="d-form-grp">
							<label class="mt-2" for="password">Password</label>
							<div>
								<TextInput
									dense
									id="password"
									:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
									v-on:click:append="show_password = !show_password"
									:type="show_password ? 'text' : 'password'"
									hide-details
									v-model="password"
									placeholder="Enter password"
									:disabled="loading"
									:loading="loading"
									class="mt-0"
									:rules="[vrules.required(password, 'Password')]"
								></TextInput>
								<template v-if="password">
									<v-layout class="mt-1">
										<v-flex>
											<v-progress-linear
												color="red darken-2"
												rounded
												height="5px"
												value="100"
											></v-progress-linear>
										</v-flex>
										<v-flex class="mx-2">
											<v-progress-linear
												:color="validate_password(password).id >= 2 ? 'yellow darken-2' : 'grey lighten-2'"
												rounded
												height="5px"
												value="100"
											></v-progress-linear>
										</v-flex>
										<v-flex>
											<v-progress-linear
												:color="validate_password(password).id == 3 ? 'green darken-2' : 'grey lighten-2'"
												rounded
												height="5px"
												value="100"
											></v-progress-linear>
										</v-flex>
									</v-layout>
									<p
										class="mt-1"
										:class="{
											'red--text text--darken-2': validate_password(password).id < 2,
											'yellow--text text--darken-2': validate_password(password).id == 2,
											'green--text text--darken-2': validate_password(password).id == 3,
										}"
									>
										Password Strength: {{ validate_password(password).value }}
									</p>
								</template>
							</div>
						</div>
						<div v-if="false" class="d-form-grp">
							<label class="mt-2" for="role">Role</label>
							<AutoCompleteInput
								dense
								id="role"
								hide-details
								v-model="role"
								placeholder="Role"
								:items="roles"
								:disabled="loading"
								:loading="loading"
								item-text="text"
								item-value="value"
								class="mt-0"
							></AutoCompleteInput>
						</div>
					</v-form>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import Phone from "@/view/components/Phone";
import ObjectPath from "object-path";
import { POST, PATCH } from "@/core/services/store/request.module";
import { ERROR } from "@/core/services/store/common.module";
import { passwordStrength } from "check-password-strength";

export default {
	name: "add-user",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: Object,
			default: () => {
				return {};
			},
		},
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler() {
				this.init();
			},
		},
	},
	data() {
		return {
			show_password: false,
			form_valid: true,
			userUUID: null,
			roles: [
				{
					text: "Admin",
					value: "admin",
				},
				{
					text: "User",
					value: "user",
				},
			],
			loading: false,
			first_name: null,
			last_name: null,
			display_name: null,
			password: null,
			phone_number: null,
			email: null,
			role: null,
		};
	},
	components: {
		AutoCompleteInput,
		EmailInput,
		TextInput,
		Phone,
	},
	methods: {
		validate_password(password) {
			return passwordStrength(password);
		},
		update_or_create() {
			this.$refs.userForm.validate();

			const formErrors = this.validateForm(this.$refs.userForm);
			if (formErrors.length) {
				this.$store.dispatch(ERROR, formErrors);
				return false;
			}

			if (!this.$refs.userForm.validate()) {
				return false;
			}

			let requestType = POST;
			let requestURL = "users";
			if (this.userUUID) {
				requestType = PATCH;
				requestURL = `users/${this.userUUID}`;
			}

			const request = {
				first_name: this.first_name,
				last_name: this.last_name,
				display_name: this.display_name,
				phone_number: this.phone_number,
				password: this.password,
				email: this.email,
			};

			this.loading = true;

			this.$store
				.dispatch(requestType, { url: requestURL, data: request })
				.then(() => {
					if (this.userUUID) {
						this.$emit("select", this.userUUID);
					}
					this.$emit("success", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
		init() {
			this.userUUID = ObjectPath.get(this.value, "uuid", null);
			this.first_name = ObjectPath.get(this.value, "first_name");
			this.last_name = ObjectPath.get(this.value, "last_name");
			this.display_name = ObjectPath.get(this.value, "display_name");
			this.phone_number = ObjectPath.get(this.value, "phone_number");
			this.email = ObjectPath.get(this.value, "email");
		},
	},
	mounted() {
		this.init();
	},
};
</script>
